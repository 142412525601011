import $ from 'jquery';

// Core
import AjaxHandler from '@core/src/js/modules/ajax-handler';
import SubmitDisable from '@core/src/js/modules/submit-disable';
import AudioTrigger from '@core/src/js/modules/audio-trigger';
import FormEnhancer from '@core/src/js/modules/form-enhancer';
import DatepickerHandler from '@core/src/js/modules/datepicker-handler';
import AddressRetriever from '@core/src/js/modules/address-retriever';
import Maps from '@core/src/js/modules/maps';

// Dashboard core
import StateEnhancer from '@core-dashboard/src/js/modules/state-enhancer';
import QueryStateHandler from '@core-dashboard/src/js/modules/query-state-handler';
import ConfirmHandler from '@core-dashboard/src/js/modules/confirm-handler';
import FHFileUpload from '@core-dashboard/src/js/modules/fh-file-upload';
import ModalEnhancer from '@core-dashboard/src/js/modules/modal-enhancer';
import CardEnhancer from '@core-dashboard/src/js/modules/card-enhancer';
import GalleryHandler from '@core-dashboard/src/js/modules/gallery-handler';
import Timeago from '@core-dashboard/src/js/modules/timeago';
import Countdown from '@core-dashboard/src/js/modules/countdown';
import NotificationHandler from '@core-dashboard/src/js/modules/notification-handler';
import AddRequestHandler from '@core-dashboard/src/js/modules/add-request-handler';

// Dashboard
import Pusher from './modules/pusher';
import ReplacementVehicleCalculator from './modules/replacement-vehicle-calculator';
import InvoiceHandler from './modules/invoice-handler';

$('html').removeClass('no-js').addClass('js');
$(document).ready(() => {
    new ConfirmHandler;
    new SubmitDisable;
    new AjaxHandler;
    new FHFileUpload;
    new Pusher;
    new ModalEnhancer;
    new AddRequestHandler;
    new InvoiceHandler;
    new ReplacementVehicleCalculator;
    new AudioTrigger;
    new FormEnhancer;
    new AddressRetriever;
    new DatepickerHandler;
    new StateEnhancer;
    new QueryStateHandler;
    new Maps;
    new CardEnhancer;
    new GalleryHandler;
    new Timeago;
    new Countdown;
    new NotificationHandler;
});
