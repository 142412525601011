import $ from 'jquery';

export default class AudioTrigger {
    constructor(selector = '.js-trigger-audio') {
        this.$triggers = $(selector);
        if ( !this.$triggers.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        require.ensure([], (require) => {
            /* global Howl */
            require('howler');

            this.$triggers.on('click', (e) => {
                const howler = new Howl({
                    src: [$(e.currentTarget).data('sound-url')]
                });

                howler.play();
            });
        });
    }
}
