import $ from 'jquery';
import qs from 'qs';

export default class QueryStateHandler {
    constructor() {
        this.parameterIds = {
            tab: 'tab',
            requestId: 'card_id'
        };

        // Event listeners
        const $document = $(document);
        $document.on('change', 'input[name^="state-tabs-"]', this._handleTabChange.bind(this));
        $document.on('change', 'input[name^="state-cardflip-"]', this._handleCardFlipChange.bind(this));
        $(window).on('load', this.scrollOnLoad.bind(this));
    }

    scrollOnLoad() {
        const requestId = (qs.parse(location.search) || {})[this.parameterIds.requestId],
              $request = $(`#request-${requestId}`);

        if ( !$request.length ) {
            return;
        }

        setTimeout(() => {
            const $window = $(window),
                  offset = 25,
                  delta = $window.height() - $request.height();

            $('html, body').scrollTop(delta <= 0 ? $request.offset().top - offset : $request.offset().top - delta / 2);

            const $siblings = $request.siblings('.flippable-card');
            $siblings.addClass('flippable-card-semi');
            $window.one('resize mousemove', () => $siblings.removeClass('flippable-card-semi'));
        });
    }

    _handleTabChange(e) {
        const $input = $(e.currentTarget);

        this._updateLocation({
            [this.parameterIds.requestId]: $input.data('request-id'),
            [this.parameterIds.tab]: $input.data('slug')
        });
    }

    _handleCardFlipChange(e) {
        const $input = $(e.currentTarget);

        // Remove details about request/tab when closing the card
        if ( !$input.is(':checked') ) {
            this._updateLocation({}, Object.values(this.parameterIds));
            return;
        }

        this._updateLocation({
            [this.parameterIds.requestId]: $input.data('request-id'),
            [this.parameterIds.tab]: $input.next('.flippable-card').find('input[name^="state-tabs-"]:checked').data('slug')
        });
    }

    _updateLocation(parameters = {}, removeKeys = []) {
        parameters = Object.assign({}, qs.parse(location.search.replace(/^\?/,'')) || {}, parameters);
        removeKeys.forEach((key) => delete parameters[key]);

        if ( !window.location.search ) {
            window.history.replaceState({}, '', `${window.location.href.replace(/\?.*/,'')}?${qs.stringify(parameters)}`);
            return;
        }

        window.history.replaceState({}, '', window.location.href.replace(window.location.search, `?${qs.stringify(parameters)}`));
    }
}
