import $ from 'jquery';

export default class SubmitDisable {
    constructor(selector = '.js-submit-disable') {
        const $elements = $(selector);
        if ( !$elements.length ) {
            return;
        }

        $elements.each((i, element) => {
            const $element = $(element);

            if ( $element.is('form') ) {
                $element.on('submit', (e) => {
                    this._disable($(e.currentTarget).find(':submit'));
                });
            } else {
                $element.on('click', (e) => {
                    this._disable($(e.currentTarget));
                });

                $element.closest('form').on('submit', (e) => {
                    this._disable($(e.currentTarget).find(selector));
                });
            }
        });
    }

    _disable($button) {
        setTimeout(() => {
            // We clone the existing button to fix issues
            // in IE resulting in empty post parameters
            const $clonedButton = $button.clone().attr('disabled', true).addClass('is-loading');
            $clonedButton.insertAfter($button);
            $button.hide();
        }, 1);
    }
}
