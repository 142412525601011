import $ from 'jquery';

export default class InvoiceHandler {
    constructor() {
        const $document = $(document);

        $document.on('click trigger', '.js-invoice-block', this._handleInvoiceBlock.bind(this));
        $document.on('click trigger', '.js-invoice-paid', this._handleInvoicePaid.bind(this));
        $document.on('click trigger', '.js-invoice-agree', this._handleInvoiceAgree.bind(this));
    }

    _handleInvoiceBlock(e) {
        e.preventDefault();
        const $button = $(e.currentTarget),
              $invoice = $button.closest('.invoice'),
              $icon = $invoice.find('.invoice-icon');

        this._sendRequest($button).then((response) => {
            const isBlocked = response.blockade;

            // Update text and URL
            const text = $button.data('alternative-text');
            $button.data('alternative-text', $button.text());
            $button.text(text);

            const url = $button.data('alternative-url');
            $button.data('alternative-url', $button.data('url'));
            $button.data('url', url);

            // Update UI
            $invoice.toggleClass('is-blocked', isBlocked);
            $icon.toggleClass('is-blocked', isBlocked);
            $invoice.find('.js-invoice-paid').prop('disabled', isBlocked).toggleClass('is-disabled', isBlocked);
        }).catch(() => {
            $invoice.addClass('card-error');
        });
    }

    _handleInvoicePaid(e) {
        e.preventDefault();
        const $button = $(e.currentTarget),
              $invoice = $button.closest('.invoice'),
              $icon = $invoice.find('.invoice-icon');

        this._sendRequest($button).then(() => {
            $button.hide();
            $invoice.addClass('is-paid');
            $icon.addClass('is-paid');
            $invoice.find('.js-invoice-block').remove();
        }).catch(() => {
            $invoice.addClass('card-error');
        });
    }

    _handleInvoiceAgree(e) {
        e.preventDefault();
        const $button = $(e.currentTarget);

        this._sendRequest($button).then(() => {
            $button.addClass('btn-unresponsive btn-link icon-check').removeClass('btn-outline js-invoice-agree').removeAttr('data-url').text($button.data('success-text'));
        }).catch(console.error);
    }

    _sendRequest($button) {
        return new Promise((resolve, reject) => {
            $button.addClass('is-loading').prop('disabled', true);

            $.ajax({
                url: $button.data('url')
            }).always(() => {
                $button.removeClass('is-loading').prop('disabled', false);
            }).done(resolve).fail(reject);
        });
    }
}
