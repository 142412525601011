import $ from 'jquery';

export default class AjaxHandler {
    constructor(selector = '.js-ajax') {
        this.selector = selector;
        $(document).on('click trigger', this.selector, this._handleClick.bind(this));
    }

    _handleClick(e) {
        e.preventDefault();

        const $target = $(e.currentTarget);
        $target.addClass('is-loading').blur().hide().show(); // Hide & show to trigger repaint

        $.ajax({
            url: $target.attr('href')
        }).done(() => {
            if ( $target.hasClass('btn') ) {
                $target.addClass('btn-success');
            }

            if ( $target.hasClass(`${this.selector.replace(/^\./, '')}-toggle`) ) {
                // Toggle href
                if ( $target.attr('data-alt-href') ) {
                    const href = $target.attr('href');
                    const altHref = $target.attr('data-alt-href');

                    $target.attr('href', altHref);
                    $target.attr('data-alt-href', href);
                }

                // Toggle text
                if ( $target.attr('data-alt-text') ) {
                    const text = $target.text();
                    const altText = $target.attr('data-alt-text');

                    $target.text(altText);
                    $target.attr('data-alt-text', text);
                }

                // Toggle classes
                if ( $target.attr('data-alt-classes') ) {
                    const classes = $target.attr('data-alt-classes-default');
                    const altClasses = $target.attr('data-alt-classes');

                    $target.removeClass(classes).addClass(altClasses);
                    $target.attr('data-alt-classes', classes);
                    $target.attr('data-alt-classes-default', altClasses);
                }

                // Toggle content
                if ( $target.attr('data-toggle-hide') || $target.attr('data-toggle-show') ) {
                    $($target.attr('data-toggle-hide')).hide();
                    $($target.attr('data-toggle-show')).show();
                }
            }
        }).always(() => {
            $target.removeClass('is-loading');
        }).fail((response) => {
            const message = response.responseJSON.hasOwnProperty('message')
                ? response.responseJSON.message
                : 'Er ging iets mis bij het uitvoeren van deze actie, probeer het later nog eens...';

            alert(message);
            console.error(response);
        });
    }
}
