import $ from 'jquery';

export default class ConfirmHandler {
    constructor(selector = '.js-confirm') {
        const $document = $(document);
        this.selectors = {
            selector: selector,
            continue: `${selector}-continue`,
            template: '[data-template-id="modal-confirm"]',
            modal: '.modal',
            container: '.modal-container'
        };
        this.classes = {
            force: `${this.selectors.selector.replace(/^(\.+)/g, '')}-force`
        };

        $document.on('click', this.selectors.selector, this._handleClick.bind(this));
        $document.on('click', this.selectors.continue, this._handleContinueClick.bind(this));
    }

    _handleClick(e) {
        const $trigger = $(e.currentTarget);
        if ( $trigger.hasClass(this.classes.force) ) {
            if ( $trigger.attr('href') ) {
                window.location = $trigger.attr('href');
            }

            return true;
        }

        e.preventDefault();
        e.stopImmediatePropagation();

        // Fallback
        const $modal = $($(this.selectors.template).text());
        if ( !$modal.length ) {
            return confirm('Weet je zeker dat je deze actie uit wil voeren?');
        }

        // Default behaviour
        $modal.data('$trigger', $trigger);
        $('body').append($modal);
        return false;
    }

    _handleContinueClick(e) {
        e.preventDefault();
        const $container = $(e.currentTarget).closest(this.selectors.modal).closest(this.selectors.container),
              $trigger = $container.data('$trigger');

        // Re-trigger (forced) the initial element
        $trigger.addClass(this.classes.force);
        if ( $trigger.hasClass('js-ajax') ) {
            $(document).trigger({
                type: 'trigger',
                target: $trigger.get(0)
            });
        } else {
            $trigger.trigger('click');
        }
        setTimeout(() => $trigger.removeClass(this.classes.force), 1);

        // Trigger close event for modal
        $(document).trigger({
            type: 'modal-close',
            target: $container.get(0)
        });
    }
}
