import $ from 'jquery';

export default class GalleryHandler {
    constructor(selector = '.js-gallery') {
        this.$galleries = $(selector);
        if ( !this.$galleries.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        require.ensure([], (require) => {
            require('magnific-popup');

            this.$galleries.on('click', function(e) {
                e.preventDefault();

                const $gallery = $(e.currentTarget);
                $gallery.blur();

                let items = [];
                $gallery.siblings('.gallery-items').children('img').each((i, image) => {
                    items.push({
                        src: $(image).attr('src'),
                        type: 'image'
                    });
                });

                $.magnificPopup.open({
                    items: items,
                    gallery: {
                        enabled: true,
                        tCounter: '<span class="mfp-counter">%curr% van %total%</span>'
                    }
                });
            });
        });
    }
}
