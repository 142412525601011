import $ from 'jquery';

export default class ReplacementVehicleCalculator {
    constructor(selector = '.js-replacement-vehicle-calculator', totalSelector = '.js-replacement-vehicle-calculator-total') {
        this.selector = selector;
        this.totalSelector = totalSelector;

        this.$calculators = $(this.selector);
        if ( !this.$calculators.length ) {
            return;
        }

        this.$calculators.each((i, calculator) => {
            const $calculator = $(calculator);

            const url = $calculator.data('url');
            if ( !url ) {
                return;
            }

            // Handle changes
            const $fields = $calculator.find('[name^="replacement_vehicle_form"]');
            $fields.on('change', () => this._handleChange(url, $calculator, $fields));
        });
    }

    _handleChange(url, $calculator, $fields) {
        this.calculate(url, $fields).then((response) => {
            $calculator.find(this.totalSelector).text(response.total);
        }).catch(console.error);
    }

    calculate(url, $fields) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                method: 'POST',
                data: $fields.serializeArray().reduce((acc, val) => {
                    const name = /\[(.*?)]/.exec(val.name)[1];
                    acc[name] = val.value;

                    return acc;
                }, {})
            }).done(resolve).fail(reject);
        });
    }
}
