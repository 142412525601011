import $ from 'jquery';

export default class Countdown {
    constructor(selector = '.js-countdown') {
        this.$countdowns = $(selector);
        if ( !this.$countdowns.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        let countdowns = [];
        this.$countdowns.each((index, countdown) => {
            const interval = 1000,
                  $countdown = $(countdown),
                  $target = $countdown.find('.js-countdown-value'),
                  currentTime = $countdown.data('countdown-current');

            let endTime = $countdown.data('countdown-end');
            if ( endTime < currentTime ) {
                endTime = currentTime;
            }

            if ( !currentTime || !endTime ) {
                console.error('Countdown requires the \'data-countdown-current\' and \'data-countdown-end\' attributes');
                return;
            }

            // Initial update
            this.update(endTime - currentTime, $target);

            let tick = ( endTime !== currentTime ) ? 1 : 0;
            countdowns[index] = setInterval(() => {
                this.update(endTime - (currentTime + tick), $target);

                // Update tick
                tick++;

                // Clear the interval if the end timestamp has been reached
                if ( currentTime + tick > endTime ) {
                    clearInterval(countdowns[index]);
                }
            }, interval);
        });
    }

    update(seconds, $target) {
        $target.text(this.secondsToHHMMSS(seconds));
    }

    secondsToHHMMSS(seconds) {
        const secondsInHour = 3600,
              minutesInHour = 60,
              sec = parseInt(seconds);

        let hh = Math.floor(sec / secondsInHour),
            mm = Math.floor((sec - (hh * secondsInHour)) / minutesInHour),
            ss = sec - (hh * secondsInHour) - (mm * minutesInHour);

        if ( hh < 10 ) hh = '0' + hh;
        if ( mm < 10 ) mm = '0' + mm;
        if ( ss < 10 ) ss = '0' + ss;

        return parseInt(hh) ? hh + ':' + mm + ':' + ss : mm + ':' + ss;
    }
}
