import $ from 'jquery';

export default class FormEnhancer {
    constructor(selector = '.js-form-submit') {
        const $elements = $(selector);
        if ( !$elements.length ) {
            return;
        }

        const buttons = 'button, [type="button"], [type="submit"]';
        $elements.filter(buttons).on('click', (e) => $(e.currentTarget).closest('form').submit());
        $elements.filter(`:not(${buttons})`).on('change', (e) => $(e.currentTarget).closest('form').submit());
    }
}
