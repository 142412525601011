import $ from 'jquery';

export default class DatepickerHandler {
    constructor(selector = '.js-datepicker') {
        this.$datepickers = $(selector);
        if ( !this.$datepickers.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        require.ensure([], (require) => {
            require('pickadate/lib/picker');
            require('pickadate/lib/picker.date');

            $.extend($.fn.pickadate.defaults, {
                monthsFull: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
                monthsShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
                weekdaysFull: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
                weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
                today: 'Vandaag',
                clear: 'Verwijderen',
                close: 'Sluiten',
                firstDay: 1,
                format: 'dddd d mmmm yyyy',
                formatSubmit: 'yyyy-mm-dd',
                hiddenName: true
            });

            this.$datepickers.not('.datepicker-initialized').each((i, datepicker) => {
                const $datepicker = $(datepicker);
                $datepicker.pickadate($datepicker.data('options') || {}).addClass('datepicker-initialized');
            });
        });
    }
}
