import $ from 'jquery';
import 'bootstrap-notify';

export default class Pusher {
    constructor() {
        this.count = 0;

        const $container = $('body');
        if ( !$container || $container.data('pusher-channel-id') === '' ) {
            return;
        }

        require.ensure([], (require) => {
            const Pusher = require('pusher-js');

            // Enable pusher logging - don't include this in production
            // if ( ['dev', 'docker'].indexOf(process.env.NODE_ENV) >= 0 ) {
            //     Pusher.log = console.log;
            // }

            const pusher = new Pusher($container.data('pusher-app-key'), {
                encrypted: true,
                authEndpoint: $container.data('auth-endpoint')
            });

            const channel = pusher.subscribe($container.data('pusher-channel-id'));

            // @todo binding string should be refactored when \Achmea\Application\Event\EventIdentifier is used (API v3)

            channel.bind('request_added', (response) => {
                this.requestAdded(response);

                if (typeof response.data.soundUrl !== 'undefined') {
                    this.playSound(response.data.soundUrl);
                }
            });

            channel.bind('request_rejected', (response) => {
                this.requestRejectedBySalvor(response);

                if (typeof response.data.soundUrl !== 'undefined') {
                    this.playSound(response.data.soundUrl);
                }
            });

            channel.bind('request_accept_by_salvage_expired', (response) => {
                this.requestAcceptBySalvageExpired(response);
            });

            channel.bind('request_assign_expired', (response) => {
                this.requestAssignExpired(response);
            });

            channel.bind('request_accept_expired', (response) => {
                this.requestAcceptBySalvorExpired(response);
            });
        });
    }

    createBadge() {
        if ( this.badgeCreated ) {
            return;
        }

        this.badge = $('.header-nav > ul > li.first > a');
        this.badge.append('<span class="header-nav-badge">');
        this.badgeCreated = true;
    }

    requestAdded(pushData) {
        this.createBadge();
        this.badge.find('.header-nav-badge').text(this.count += 1);
        this.showNotification('Nieuwe melding', 'warning', pushData.data.url);
    }

    requestRejectedBySalvor(pushData) {
        this.createBadge();
        this.badge.find('.header-nav-badge').text(this.count += 1);
        this.showNotification('Melding afgewezen', 'success', pushData.data.url);
    }

    requestAcceptBySalvageExpired(pushData) {
        this.showNotification('Melding accepteren door bergingsbedrijf verlopen', 'warning', pushData.data.url);
    }

    requestAssignExpired(pushData) {
        this.showNotification('Melding toewijzing aan monteur verlopen', 'warning', pushData.data.url);
    }

    requestAcceptBySalvorExpired(pushData) {
        this.showNotification('Melding accepteren door monteur verlopen', 'warning', pushData.data.url);
    }

    showNotification(message, type, url) {
        /* eslint-disable camelcase */
        $.notify({
            message: `<strong>${message}</strong>`
        }, {
            element: 'body',
            type: 'success',
            allow_dismiss: true,
            placement: {
                from: 'top',
                align: 'center'
            },
            offset: 16,
            spacing: 10,
            z_index: 1031,
            delay: 0,
            animate: {
                enter: 'animated fadeInDown',
                exit: 'animated fadeOutUp'
            },
            icon_type: 'class',
            template: [
                '<div class="notification-container container text-center" data-notify="container">',
                    `<div class="notification notification-push is-${type}" role="alert"> {2}`,
                        `<a href="${url}" class="notification-anchor"></a>`,
                        '<button type="button" class="notification-close icon-cancel" data-notify="dismiss"></button>',
                    '</div>',
                '</div>'
            ].join('\n')
        });
        /* eslint-enable */
    }

    playSound(src) {
        require.ensure([], (require) => {
            const Howl = require('howler').Howl;
            const howler = new Howl({
                src: [src]
            });

            howler.play();
        });
    }
}
