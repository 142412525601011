import $ from 'jquery';

export default class NotificationHandler {
    constructor(selector = '.js-notification') {
        this.$notification = $(selector);
        if ( !this.$notification.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        require.ensure([], (require) => {
            require('bootstrap-notify');

            /* eslint-disable camelcase */
            $.notify({
                // Options
                message: this.$notification.data('message')
            }, {
                // Settings
                element: 'body',
                type: this.$notification.data('type'),
                allow_dismiss: true,
                placement: {
                    from: 'top',
                    align: 'center'
                },
                offset: 16,
                spacing: 10,
                z_index: 1031,
                delay: 0,
                animate: {
                    enter: 'animated fadeInDown',
                    exit: 'animated fadeOutUp'
                },
                icon_type: 'class',
                template: [
                    '<div class="container" data-notify="container">',
                        '<div class="col-xs-12 col-sm-10 col-sm-offset-1 notification notification-push is-{0}" role="alert"> {2} <button type="button" class="notification-close icon-cancel" data-notify="dismiss"></button></div>',
                    '</div>'
                ].join('\n')
            });
            /* eslint-enable */
        });
    }
}
