import $ from 'jquery';

export default class ModalEnhancer {
    constructor(selector = '.modal') {
        const $document = $(document);
        this.selectors = {
            selector: selector,
            close: `${selector}-close`,
            container: `${selector}-container`,
            state: {
                hidden: 'is-hidden'
            }
        };

        $document.on('click', this.selectors.close, this._handleCloseClick.bind(this));
        $document.on('modal-open', this.selectors.container, this._handleOpen.bind(this));
        $document.on('modal-close', this.selectors.container, this._handleClose.bind(this));
    }

    _handleCloseClick(e) {
        e.preventDefault();
        $(e.currentTarget).closest(this.selectors.container).remove();
    }

    _handleOpen(e) {
        $(e.currentTarget).removeClass(this.selectors.state.hidden);
    }

    _handleClose(e) {
        $(e.currentTarget).remove();
    }
}
