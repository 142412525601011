import $ from 'jquery';

export default class CardEnhancer {
    constructor(selector = '.card') {
        this.$cards = $(selector);
        if ( !this.$cards.length ) {
            return;
        }

        this.initializeFlipEnhancer();
        this.initializeTabEnhancer();
    }

    initializeFlipEnhancer() {
        this.$flipableCards = $('.flippable-card');
        if ( !this.$flipableCards.length ) {
            return;
        }

        const multiplier = 4; // Height multiplier for perspective
        this.$flipableCards.each((i, card) => {
            const $card = $(card);

            $card.prev('.toggle-state-flip').on('change', (e) => {
                const $state = $(e.currentTarget),
                      $card = $state.next('.flippable-card');

                // Improve perspective
                const height = $card.outerHeight() * multiplier;
                if ( $state.is(':checked') ) {
                    $card.css('transform', `perspective(${height}px) rotateY(-180deg)`);
                } else {
                    $card.css('transform', `perspective(${height}px)`);

                    // Reset after transition to fix perspective issues when re-flipping
                    const transitionDuration = 750;
                    setTimeout(() => {
                        $card.css('transform', '');
                    }, transitionDuration);
                }
            });
        });
    }

    initializeTabEnhancer() {
        this.$cardTabs = this.$cards.find('.card-tabs');
        if ( !this.$cardTabs.length ) {
            return;
        }

        $(window).on('resize', () => {
            this.$cardTabs.each((i, tabs) => {
                const $items = $(tabs).find('.card-tabs-items');

                $items.css('width', () => {
                    return $items.children().get().reduce((width, child) => {
                        return width + $(child).outerWidth(true);
                    }, ($items.outerWidth() - $items.width()) + 2);
                });
            });
        }).trigger('resize');
    }
}
